<template>
  <vue-tags-input v-model="tag"
                  :tags="jobs"
                  :is-duplicate="isDuplicate"
                  :autocomplete-items="filteredItems"
                  @tags-changed="newTags => jobs = newTags"
                  placeholder=" " />
</template>

<script>
  import '../../plugins/vue-tags-input';
  import customerService from '../../services/customer';

  export default {
    name: "JobTagsInput",
    props: {
      job: {
        required: false,
        type: String
      }
    },
    data() {
      return {
        tag: '',
        jobs: [],
        jobList: [],
        loaded: false
      };
    },
    methods: {
      getJobs() {
        customerService.getJobs(this.tag).then(response => {
          this.jobList = response;
        });
      },
      isDuplicate() {
        if (this.tag) {
          return this.jobs.map(t => t.text.toLowerCase()).indexOf(this.tag.toLowerCase()) !== -1;
        }
      }
    },
    watch: {
      tag: function () {
        if (this.tag != '' && this.tag.length > 2) {
          this.getJobs();
        }
      },
      jobs: {
        handler: function () {
          if (this.jobs.length > 0) {
            let job = '';
            for (var i = 0; i < this.jobs.length; i++) {
              job += this.jobs[i].text;

              if (i < this.jobs.length - 1)
                job += ','
            }
            this.$parent.$parent.$parent.$parent.model.customer_OtherInformation.job = job;
          }
        },
        deep: true
      },
      job: {
        handler: function () {
          if (!this.loaded && this.job != null && this.job != '') {
            if (this.job.includes(',')) {
              let data = this.job.split(',');

              for (var i = 0; i < data.length; i++) {
                this.jobs.push({ text: data[i] });
              }

              this.loaded = true;
            }
            else if (this.job != "undefined") {
              this.jobs.push({ text: this.job });
              this.loaded = true;
            }
          }
        },
        deep: true
      }
    },
    computed: {
      filteredItems() {
        return this.jobList.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        });
      }
    },
    mounted: function () {

    }
  };
</script>


<style>
  .vue-tags-input {
    max-width: 100% !important;
  }

  .vue-tags-input .ti-input{
    border-radius: 10px;
  }

  .vue-tags-input .ti-tag.ti-valid {
    background-color: #4cb0c5;
  }

  .ti-selected-item {
    background-color: #4cb0c5 !important;
  }
</style>
